<template>
  <Transition
    name="fade"
    mode="out-in"
    enter-from-class="opacity-0"
    enter-active-class="transition-opacity ease-in duration-300"
    enter-to-class="opacity-100"
    leave-from-class="opacity-100"
    leave-active-class="transition-opacity ease-out duration-300"
    leave-to-class="opacity-0"
    v-bind="$attrs"
  >
    <slot />
  </Transition>
</template>
